import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allmembersCount : 0,
    followingcount : 0,
    followerscount :0,
    mygroupcount : 0,
};

const profile = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setAllMemeberscount(state, action) {
            state.allmembersCount = action.payload;
        },
       setfollowingcount(state, action) {
            state.followingcount = action.payload;
        }, 
        setfollowerscount(state, action) {
            state.followerscount = action.payload;
        },
        setmygroupcount(state, action) {
            state.mygroupcount = action.payload;
        },
        setAddRemovefriend(state,action){
            if(action.payload===1){
                state.followingcount +=1
            }
            else  {
                state.followingcount -=1
            }
        },
       
    }
});

// Export the actions
export const { setAllMemeberscount, setfollowingcount, setfollowerscount, setmygroupcount, setAddRemovefriend} = profile.actions;

export default profile.reducer;