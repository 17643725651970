// third-party
import jwt from 'jsonwebtoken';

// project imports
import services from 'utils/mockAdapter';
import { JWT_API, API_URL } from 'config';
import axios from "axios";

// constant
const JWT_SECRET = JWT_API.secret;
const JWT_EXPIRES_TIME = JWT_API.timeout;

const SERVER_API_URL = API_URL;

const delay = (timeout) => new Promise((res) => setTimeout(res, timeout));

let users = [
    {
        id: '5e86809283e28b96d2d38537',
        email: 'info@codedthemes.com',
        password: '123456',
        name: 'JWT User'
    }
];

let resp= [];

// ==============================|| MOCK SERVICES ||============================== //

services.onPost('/api/account/login').reply(async (request) => {
    try {
        //  await delay(500);
        const { email, password } = JSON.parse(request.data);
      
        const API_URL = SERVER_API_URL+"/api/auth/signin";
        const ServerResponse = await axios.post(API_URL, { email, password });
        
      
        
        if(!ServerResponse.data.status || ServerResponse.data.status !== '200'){
            resp = [500, { message: ServerResponse.data.message }];
        } else if(ServerResponse.data.data.id) {
            const serviceToken = ServerResponse.data.data.accesstoken;
            
            resp = [
                200,
                {
                    serviceToken,
                    user: {
                        id: ServerResponse.data.data.id,
                        email: ServerResponse.data.data.email,
                        name: ServerResponse.data.data.email,
                        groupId: ServerResponse.data.data.groupId
                    }
                }
            ];
            
        } else if(ServerResponse.data.data.message) {
            resp = [500, { message: ServerResponse.data.data.message }];
            
        }
        return resp;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/account/register').reply(async (request) => {
    try {
        await delay(500);

        const { id, email, password, firstName, lastName, mobile } = JSON.parse(request.data);

        if (!email || !password) {
            return [400, { message: 'Enter Your Email & Password' }];
        }

        if (!firstName || !lastName) {
            return [400, { message: 'Enter Your Name' }];
        }
       
      
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/auth/signup", { id, email, password, firstName, lastName, mobile });
       

        
        return [200, ServerResponseNext.data];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/user/Email').reply(async (request) => {
    try {
        const {userId} = JSON.parse(request.data);
        console.log(JSON.stringify(request.data));
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/Email", {userId});
       console.log(JSON.stringify(ServerResponseNext.data));
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onGet('/api/account/me').reply((request) => {
    try {
        const { Authorization } = request.headers;

        if (!Authorization) {
            return [401, { message: 'Token Missing' }];
        }

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = JSON.parse(localUsers);
        }

        
        let serviceToken = Authorization.toString();
        serviceToken = serviceToken.split(' ')[1];
      
        const jwData = jwt.verify(serviceToken, JWT_SECRET);
       
        const { userId } = jwData;
        const user = Array.isArray(users) ? users.find((_user) => _user.id === userId) : 1;
        if (!user) {
            return [401, { message: 'Invalid Token' }];
        }

        return [
            200,
            {
                user: {
                    id: user.id,
                    email: user.email
                }
            }
        ];
    } catch (err) {
     
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/account/forgot').reply(async (request) => {
    try {
        await delay(500);

        const {email} = JSON.parse(request.data);

       
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/account/forgot", { email});
      
        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            users = [
                ...users,
                {
                    userId: ServerResponseNext.data.data
                }
            ];
            userRes = [200, ServerResponseNext.data.data];
        } else {
            userRes = [201, ServerResponseNext.data];
        }
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});


services.onPost('/api/account/forgot/verifyotp').reply(async (request) => {
    try {

        const {UserId,otp} = JSON.parse(request.data);

     
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/account/forgot/verifyotp", {UserId,otp });
     
        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            users = [
                ...users,
                {
                    userId: ServerResponseNext.data
                }
            ];
            userRes = [200, ServerResponseNext.data];
        } else {
            userRes = [201, ServerResponseNext.data];
        }
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});


services.onPost('/api/auth/reset_password').reply(async (request) => {
    try {

        const {UserId,Password} = JSON.parse(request.data);

      
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/auth/reset_password", {UserId,Password });
      
        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            users = [
                ...users,
                {
                    userId: ServerResponseNext.data
                }
            ];
            userRes = [200, ServerResponseNext.data];
        } else {
            userRes = [201, ServerResponseNext.data];
        }
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});
services.onPost('/api/account/registerotpverify').reply(async (request) => {
    try {
        await delay(500);

        const { id, regUserId, otp } = JSON.parse(request.data);
        
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/auth/register_otp_verify", { id, regUserId, otp });
        return [200, ServerResponseNext.data];
        //  return [200, 'Success'];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/account/registerotpverify').reply(async (request) => {
    try {
        await delay(500);

        const { id, regUserId, otp } = JSON.parse(request.data);
       
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/auth/register_otp_verify", { id, regUserId, otp });
        return [200, ServerResponseNext.data];
        //  return [200, 'Success'];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
}); 

services.onPost('/api/user/profile_view').reply(async (request) => {
    try {
        const { userId } = JSON.parse(request.data);
		const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/profile_view", { userId });
        return [200, ServerResponseNext.data];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/user/profile_update').reply(async (request) => {
    try {
        const {fname,lname,city,address,tradingviewIid,occupation,userId } = JSON.parse(request.data);
        const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/profile_update", {fname,lname,city,tradingviewIid,address,occupation,userId });
        return [200, ServerResponseNext.data];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/user/Sociallink_view').reply(async (request) => {
    try {
        const { userId } = JSON.parse(request.data);
       const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/Sociallink_view", { userId });
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/user/Sociallinkupdate').reply(async (request) => {
    try {
        const {about,instaLink,fbLink,twitterLink,linkedinLink,userId } = JSON.parse(request.data);
        
		const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/Sociallinkupdate", { about,instaLink,fbLink,twitterLink,linkedinLink,userId });
        return [200, ServerResponseNext.data];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/follow_member').reply(async (request) => {
    try {
        const {userId,followUserId} = JSON.parse(request.data);
       
		const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/follow_member", {userId,followUserId});
        return [200, ServerResponseNext.data];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/unfollow_member').reply(async (request) => {
    try {
  
        const {userId,followUserId} = JSON.parse(request.data);
       
		const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/unfollow_member", {userId,followUserId});
        return [200, ServerResponseNext.data];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/user/Qscore').reply(async (request) => {
    try {
        const {userId} = JSON.parse(request.data);
        console.log(JSON.stringify(request.data));
		const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/Qscore", {userId});
       console.log(JSON.stringify(ServerResponseNext.data));
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/user/rewardschart').reply(async (request) => {
    try {
       
        const {userId} = JSON.parse(request.data);
        console.log(JSON.stringify(request.data));
       const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/RewardsCharts", {userId});
       console.log(JSON.stringify(ServerResponseNext.data));
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/followers_list').reply(async (request) => {
    try {
        const {userId,search,  page, pageSize, CurrentUserId} = JSON.parse(request.data);
		const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/followers_list", {userId,search,page, pageSize, CurrentUserId});
      
        return [200, ServerResponseNext];
    } catch (err) {
        return [500, { message: 'Server Error' }];
    }
});
services.onPost('/api/following_list').reply(async (request) => {
    try {
        const {userId,search , page, pageSize} = JSON.parse(request.data);
       
		const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/following_list", {userId,search, page, pageSize});
        
        return [200, ServerResponseNext];
    } catch (err) {
        return [500, { message: 'Server Error' }];
    }
});
services.onPost('/api/mygroup_members_list').reply(async (request) => {
    try {
        const {userId, groupId, search, page, pageSize} = JSON.parse(request.data);
       
		const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/mygroup_members_list", {userId, groupId, search, page, pageSize});
       
        return ServerResponseNext;
    } catch (err) {
        return [500, { message: 'Server Error' }];
    }
});
services.onPost('/api/user/AllUsers').reply(async (request) => {
    try {
       
        const {userId, search, GroupFilterstate, searchfilterstate, page, pageSize} = JSON.parse(request.data);
       const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/AllUsers",{userId, search, GroupFilterstate,searchfilterstate, page, pageSize});
        return ServerResponseNext;
    } catch (err) {
        return [500, { message: 'Server Error' }];
    }
});



services.onPost('/api/members_statistics').reply(async (request) => {
    try {
        const {userId,memberId} = JSON.parse(request.data);
        
		const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/members_statistics", {userId,memberId});
       
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/view_member_profile').reply(async (request) => {
    try {
        const {userId,memberId} = JSON.parse(request.data);
        
		const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/view_member_profile", {userId,memberId});
    
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});


services.onPost('/api/user/insertwatch_list').reply(async (request) => {
    try {
       
      
        const {companyId,userId,watchtype} = JSON.parse(request.data);
       
        
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/watchlist/addwatchlist", {companyId,userId,watchtype});
       
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});
services.onPost('/api/user/watch_list').reply(async (request) => {
    try {
        const {watchType} = JSON.parse(request.data);
      
        const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        // const ServerResponseNext = await axios.get(SERVER_API_URL+"/api/coreaudit/getcompanylist",{});
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/watchlist/getcompanylist",{watchType});
        
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});


services.onPost('/api/user/weeklist').reply(async (request) => {
    try {

        const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/weeklist");
        
        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            
            userRes = ServerResponseNext;
        } else {
           
            userRes = ServerResponseNext;
        }
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});



services.onPost('/api/user/DeleteWatchlist').reply(async (request) => {
    try {

        const {userId,weekNum,watchId} = JSON.parse(request.data);
       
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/watchlist/deletewatchlisthistory", {userId,weekNum,watchId});
       
        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            userRes = ServerResponseNext;
        } else {
          
            userRes = ServerResponseNext;
        }
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});



services.onPost('/api/user/weekly').reply(async (request) => {
    try {
       
        const {userId,watchType,weeknum} = JSON.parse(request.data);
        
       
       const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
       
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/watchlist/getwatchlisthistory", {userId,watchType,weeknum});
       
      
        
        let userRes = [];
        const Len=ServerResponseNext.data.data.length;
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200' && Len !== 0) {
            
            userRes = ServerResponseNext;
        } else {
            
            userRes = ServerResponseNext;
        }
        
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});















services.onPost('/api/user/Resources').reply(async (request) => {
    try {
       const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/Resources");
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error Resources' }];
    }
});

services.onPost('/api/user/Research').reply(async (request) => {
    try {
       const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/Research");
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error Resources' }];
    }
});

services.onPost('/api/user/Templates').reply(async (request) => {
    try {
       const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/Templates");
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error Resources' }];
    }
});
//REOURCE ASSIGNED


services.onPost('/api/user/Techno').reply(async (request) => {
    try {
       const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/Techno");
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error Resources' }];
    }
});

services.onPost('/api/user/apps').reply(async (request) => {
    try {
       const serviceToken = window.localStorage.getItem("serviceToken");
		axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/apps");
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error Resources' }];
    }
});

services.onPost('/api/user/GetLearing').reply(async (request) => {
    try {
        const {topicType} = JSON.parse(request.data);
        
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/GetLearing",{topicType});
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error Resources' }];
    }
});


services.onPost('/api/user/InsertLearningHistory').reply(async (request) => {
    try {
        const {userid,topicid} = JSON.parse(request.data);
        
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/InsertLearningHistory",{userid,topicid});
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error Resources' }];
    }
});

services.onPost('/api/user/CheckStatusOfLearning').reply(async (request) => {
    try {
        const {userid,topicType} = JSON.parse(request.data);
       
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/CheckStatusOfLearning",{userid,topicType});
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error Resources' }];
    }
});

services.onPost('/api/LearnPath/DeleteLearningHistory').reply(async (request) => {
    try {
        const {userid,topicid} = JSON.parse(request.data);
       
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/LearnPath/DeleteLearningHistory",{userid,topicid});
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error Resources' }];
    }
});


services.onPost('/api/LearnPath/CheckTotalCountOfLearning').reply(async (request) => {
    try {
        const {topicType} = JSON.parse(request.data);
        
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/LearnPath/CheckTotalCountOfLearning",{topicType});
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error Resources' }];
    }
});


services.onPost('/api/Profile/Alloccupationlist').reply(async (request) => {
    try {
        // const {} = JSON.parse(request.data);
        // const {userId}=129;
        
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/Profile/Alloccupationlist");
       
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error getcompanylist' }];
    }
});


services.onPost('/api/Profile/processUpdate').reply(async (request) => {
    try {
        const {profileProcess,userId } = JSON.parse(request.data);
      
     
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/Profile/processUpdate", {profileProcess,userId});
        return [200, ServerResponseNext.data];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/user/watchlist_data').reply(async (request) => {
    try {

        const {userId,watchType,weeknum,CurrentYear} = JSON.parse(request.data);
        
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/watchlist/getwatchlisthistory", {userId,watchType,weeknum,CurrentYear});

        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            
            userRes = ServerResponseNext;
        } else {
            userRes = ServerResponseNext;
        }
     
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});


services.onPost('/api/coreaudit/getcoreauditcompanylist').reply(async (request) => {
    try {

       
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/coreaudit/getcoreauditcompanylist");
       
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error getcompanylist' }];
    }
});

services.onPost('/api/coreaudit/getcoreaudit').reply(async (request) => {
    try {
        // const {} = JSON.parse(request.data);
        // const {userId}=129;
        const { userId,quarterly,CurrentYear } = JSON.parse(request.data);
        
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/coreaudit/getcoreaudit",{userId,quarterly,CurrentYear});
      
        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error getcompanylist' }];
    }
});

services.onPost('/api/coreaudit/coreauditupdate').reply(async (request) => {
    try {
        const { onInvesting,onCurrent,returnVal,comment,HP,userId,companyId } = JSON.parse(request.data);
       
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/coreaudit/coreauditupdate", { onInvesting,onCurrent,returnVal,comment,HP,userId,companyId });
        return [200, ServerResponseNext.data];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});


services.onPost('/api/coreaudit/coreauditinsert').reply(async (request) => {
    try {
        const { userId, companyId,quarterly } = JSON.parse(request.data);
       
     
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/coreaudit/coreauditinsert", { userId, companyId,quarterly });
        return [200, ServerResponseNext.data];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});
services.onPost('/api/coreaudit/coreauditDelete').reply(async (request) => {
    try {
        const { userId,companyId,quarterly } = JSON.parse(request.data);
      
     
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/coreaudit/coreauditDelete", { userId,companyId,quarterly });
        return [200, ServerResponseNext.data];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});


services.onPost('/api/user/Quantum_Quiz_Questions').reply(async (request) => {
    try {

       
     
       
        const {Status1} = JSON.parse(request.data);
      
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/Quantum",{Status1});
      
    
        
        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            
            userRes = ServerResponseNext;
        } else {
     
            userRes = ServerResponseNext;
        }
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});
services.onPost('/api/user/Quantum_insertdata').reply(async (request) => {
    try {

        const {User_id,question,answer} = JSON.parse(request.data);
        
       
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/Quantum_insertdata",{User_id,question,answer});
   
        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            
            userRes = ServerResponseNext;
        } else {
            
            userRes = ServerResponseNext;
        }
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/user/checkuserIddata').reply(async (request) => {
    try {

        const {User_Id,weekNumber} = JSON.parse(request.data);
        
     
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/checkuserIddata",{User_Id,weekNumber});

        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            
            userRes = ServerResponseNext;
        } else {
           
            userRes = ServerResponseNext;
        }
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/user/checkanswerdata').reply(async (request) => {
    try {

        const {User_Id,weekNumber} = JSON.parse(request.data);
        
        
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/checkanswerdata",{User_Id,weekNumber});
    
        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            
            userRes = ServerResponseNext;
        } else {
            
            userRes = ServerResponseNext;
        }
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/user/charity_list').reply(async (request) => {
    try {

        const {val1} = JSON.parse(request.data);

       
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/charity_list", {});
      
        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            
            userRes = ServerResponseNext;
        } else {
         
            userRes = ServerResponseNext;
        }
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});


services.onPost('/api/user/charity_listdata').reply(async (request) => {
    try {

        const {userid} = JSON.parse(request.data);

    
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/charity_listdata", {userid});
        
        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            
            userRes = ServerResponseNext;
        } else {
            
            userRes = ServerResponseNext;
        }
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/user/Editcharitydata').reply(async (request) => {
    try {

        const {charityListid,year1,month,amount,charityList,userId} = JSON.parse(request.data);

       
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/editcharitydata", {charityListid,year1,month,amount,charityList,userId });
        
        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            
            userRes = ServerResponseNext;
        } else {
          
            userRes = ServerResponseNext;
        }
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/user/delete_charitylistdata').reply(async (request) => {
    try {
        // const {nameCom} = JSON.parse(request.data);

        const {nameCom,userid,month,amount} = JSON.parse(request.data);
       
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/delete_charitylistdata", {nameCom,userid,month,amount});
        
       
        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            
            userRes = ServerResponseNext;
        } else {
           
            userRes = ServerResponseNext;
        }
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/user/insertcharity_list').reply(async (request) => {
    try {
       
        const {charityid} = JSON.parse(request.data);
        const {userid} = JSON.parse(request.data);
        const {year} = JSON.parse(request.data);  
         const {month} = JSON.parse(request.data);
         const {amount} = JSON.parse(request.data);
        const {addedon} = JSON.parse(request.data);

     
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/insertcharity_list", {charityid,userid,year,month,amount,addedon});

        return ServerResponseNext;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});

services.onPost('/api/user/weekdata1').reply(async (request) => {
    try {

        const {charityid,userid,watchtype} = JSON.parse(request.data);

        
        const ServerResponseNext = await axios.post(SERVER_API_URL+"/api/user/weekdata1", {charityid,userid,watchtype});
        
        let userRes = [];
        if(ServerResponseNext.data.status && ServerResponseNext.data.status === '200') {
            
            userRes = ServerResponseNext;
        } else {
          
            userRes = ServerResponseNext;
        }
        return userRes;
    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }
});