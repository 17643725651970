import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   istimer:false,
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const timerupdate = createSlice({
    name: 'timerupdate',
    initialState,
    reducers: {
        istimerupdate(state, action) {
             state.istimer= action.payload;
        },
    }
});

export default timerupdate.reducer;

export const { istimerupdate } = timerupdate.actions;
