import dashboard from './dashboard';
import Calculator from './Calculators';
import Events from './Events';
import StockAlert from './StockAlert';
import WatchlistSearch from './WatchlistSearch';
import CoreAudit from './CoreAudit';
import QuantumQuiz from './QuantumQuiz';
import MyActions from './MyActions';
import Charity from './Charity';
import Learning from './Learning';
import Profile from './Profile';
import Habits from './Habits';
import Resources from './Resources';
import Help from './Help';
import Others from './other';
import Admin from './Admin';
import CNAdmin from './CnAdmin';
import { useDispatch, useSelector } from 'store';
import Calculators from './Calculators';
import Stocks from './Stocks';
import QuantumInvestingChampion from './QIChamp';
import QIChamp from './QIC';
import MarketDashboard from './Market_dashboard';
// ==============================|| MENU ITEMS ||============================== //
const Usersid = window.localStorage.getItem("users");

// const Suserid = useSelector((state)=>state.counter.value);

let menuItems={};
menuItems = {
    items: [Profile,dashboard,QIChamp,Stocks,MyActions,Learning,Others]
};
if(Usersid){
    const { role_id , cn_role_id } = JSON.parse(Usersid);
    if(role_id!==1){
        menuItems = {
           items: [Profile,Stocks,MyActions,Learning,QIChamp,Others]
       };
   }
   if( role_id !== 1 && cn_role_id === 3 || cn_role_id === 2){
    menuItems = {
       items: [Profile,Stocks,CNAdmin, MyActions, Learning,QIChamp,Others]
   };
  }
  if(role_id === 1 || role_id === 2 && (cn_role_id === 3 || cn_role_id === 2)){
    menuItems = {
       items: [Profile,Stocks,MyActions,Learning,QIChamp,Others,Admin]
   };
   }
   if(role_id === 1 || role_id === 2){
    menuItems = {
       items: [Profile,Stocks,MyActions,Learning,QIChamp,Others,Admin]
   };
   }
   if(role_id === 3 && (cn_role_id === 3 || cn_role_id === 2)){
    menuItems = {
       items: [Profile,Stocks, MyActions,Learning,QIChamp,Others,Admin] //MarketDashboard
   };
   }
   if(role_id === 3){
    menuItems = {
       items: [Profile,Stocks, MyActions,Learning,QIChamp,Others,Admin] // MarketDashboard
   };
   }
   
}

export default menuItems;


